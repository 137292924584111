import React, {useContext} from 'react';
import { Layout } from 'antd';
import KHHeader from './KHHeader';

import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {AuthContext} from "../contexts/AuthContext";
import {AnimatedAuthUser} from "../Utilities";


function AdvancedSubMenu({curProjectId, ...props}) {
  const authContext = useContext(AuthContext);
  const user = new AnimatedAuthUser(authContext.user);
  if (!user.isAdmin()) return null;

  return (
      <Menu.SubMenu title="Advanced" {...props}>
        <Menu.Item key="jobs" {...props}>
          <Link to={`/project/${curProjectId}/jobs`}>
            Jobs
          </Link>
        </Menu.Item>
      </Menu.SubMenu>
  );
}


export class ProjectWorkspaceLayout extends React.Component {


  render() {
    let selectedMenuItem
    const curProjectId = this.props.match.params.projectId
    switch (this.props.location.pathname.split('/')[3]) {
      case 'upload_documents':
        selectedMenuItem = 'upload_documents'
        break;
      case 'statistics':
        selectedMenuItem = 'statistics'
        break;
      case 'extract_tags':
        selectedMenuItem = 'extract_tags'
        break;
      case 'explore_results':
        selectedMenuItem = 'explore_results'
        break;
      case 'samples':
        selectedMenuItem = 'samples'
        break;
      case 'final_results':
        selectedMenuItem = 'final_results'
        break;
      case 'explore_files':
        selectedMenuItem = 'explore_files'
        break;
      case 'explore_tags':
        selectedMenuItem = 'explore_tags'
        break;
      case 'settings':
        selectedMenuItem = 'settings'
        break;
      case 'jobs':
        selectedMenuItem = 'jobs'
        break;
      default:
        selectedMenuItem = 'upload_documents'
    }
    return (
      <Layout style={{ height: "100vh" }}>
        <KHHeader />
        <Layout>
          <Layout.Sider width={'20%'} theme='light'>
          <Menu theme="light" mode="inline" selectedKeys={[selectedMenuItem]}>
            <Menu.Item key="upload_documents">
              <Link to={`/project/${curProjectId}/upload_documents`}>
                Upload documents
              </Link>
            </Menu.Item>
            <Menu.Item key="explore_files">
              <Link to={`/project/${curProjectId}/explore_files`}>
                Explore files
              </Link>
            </Menu.Item>
            <Menu.Item key="statistics">
              <Link to={`/project/${curProjectId}/statistics`}>
                Statistics
              </Link>
            </Menu.Item>
            <Menu.Item key="extract_tags">
              <Link to={`/project/${curProjectId}/extract_tags`}>
                Extract tags
              </Link>
            </Menu.Item>
            <Menu.Item key="samples">
              <Link to={`/project/${curProjectId}/samples`}>
                Training samples
              </Link>
            </Menu.Item>
            <Menu.Item key="explore_results">
              <Link to={`/project/${curProjectId}/explore_results`}>
                Explore results
              </Link>
            </Menu.Item>
            <Menu.Item key="explore_tags">
              <Link to={`/project/${curProjectId}/explore_tags`}>
                Explore tags
              </Link>
            </Menu.Item>
            <Menu.Item key="final_results">
              <Link to={`/project/${curProjectId}/final_results`}>
                Finalized results
              </Link>
            </Menu.Item>
            <Menu.Item key="settings">
              <Link to={`/project/${curProjectId}/settings`}>
                Settings
              </Link>
            </Menu.Item>
            <AdvancedSubMenu curProjectId={curProjectId}/>
          </Menu>
          </Layout.Sider>
          <Layout.Content style={{ padding: 24, margin: 24 }} className="site-layout-background">
            {this.props.children}
          </Layout.Content>
        </Layout>
      </Layout>
    )
  }
}
