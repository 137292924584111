import axios from "axios";
import {API_URL} from "../constants";
import {authHeader} from "../Utilities";

class AllResultsService {
  getAnnotationObject(tagReference) {
    return axios.post(API_URL + `/all_results/get_annotation_object`, {
      tag_reference: tagReference,
    }, {headers: authHeader()});
  }
}

export default new AllResultsService();
