import React from 'react';
import { Layout } from 'antd';
import KHHeader from './KHHeader';

import { Menu } from 'antd';
import { Link } from 'react-router-dom';


export class DocumentViewLayout extends React.Component {
  
  render() {
    return (
      <Layout style={{height:"100vh"}}>
        <KHHeader />
        <Layout>
          {/* <Layout.Sider width={'20%'} theme='light'>
          </Layout.Sider> */}
          <Layout.Content style={{padding: 12, margin: 12, display: 'flex'}} className="site-layout-background">
            {this.props.children}
          </Layout.Content>
        </Layout>
      </Layout>
    ) 
  }
}
