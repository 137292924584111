import axios from "axios";
import {API_URL} from "../../../constants";
import {authHeader, cache} from "../../../Utilities";


class AssignTarget {
    constructor(response) {
        this._response = response;
    }

    asCommentSelectOption() {
        const userId = this._response.user_id;

        return {
            key: userId,
            value: JSON.stringify(this.asDataToAssign()),
            label: this._response.name
        }
    }

    asDataToAssign() {
        return {
            userId: this._response.user_id,
            name: this._response.name
        }
    }
}

export class AssignTargets {
    constructor(imageViewer) {
        this._imageViewer = imageViewer;

        this._getTargets = (q) => {
            const params = {...this._imageViewer.toResultReference(), q}
            return axios.get(
                API_URL + `/comments/assign_targets`,
                {params: params, headers: authHeader()}
            );
        }
    }

    async values(text) {
        return new Promise((resolve, reject) => {
            this._getTargets(text)
                .then(response => resolve(
                    response.data
                        .map(el => new AssignTarget(el))
                ))
                .catch(e => reject())
        })
    }

    defaultCommentOptionValue(comment) {
        const assignTarget = comment.assignTargets[0];

        return {
            key: assignTarget?.userId ?? null,
            value: assignTarget?.userId ?? null,
            label: assignTarget?.name ?? null
        }
    }
}