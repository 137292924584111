import {Button, Popover} from "antd";
import React from "react";

export function AddFilterButton({id, onVisibleChange, contentVisible, content}) {
    return (
        <Popover
            visible={contentVisible}
            destroyTooltipOnHide={true}
            trigger="click"
            title="Add filter"
            placement="bottom"
            content={content}
            onVisibleChange={onVisibleChange}
        >
            <Button
                id={id}
                icon={<img src={process.env.PUBLIC_URL + '/images/filter_list-24px.svg'}/>}
                size="small"
            >
                Add filter
            </Button>
        </Popover>
    );
}