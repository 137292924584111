import ObjectReference from "./ObjectReference";
import HierarchiesService from "../../../services/HierarchiesService";
import AllResultsService from "../../../services/AllResultsService";
import ImageViewerObject from "../ImageViewerObject";
import {message} from "antd";

export default class HierarchyNode {
  constructor({id, text, label, parent_node_id, references, attributes, hierarchy, loading_info}) {
    this.id = id;
    this.text = text;
    this.label = label;
    this.parent_node_id = parent_node_id;
    this.references = references;
    this.attributes = attributes;
    this.hierarchy = hierarchy;
    this.loading_info = loading_info;
  }

  // returns null for the root
  getParent() {
    return this.parent_node_id ? this.hierarchy.getNodeById(this.parent_node_id) : null;
  }

  isVirtual() {
    return this.references.length === 0;
  }

  isRoot() {
    return this.parent_node_id === null;
  }

  isLeaf() {
    if (this.isRoot()) return false;
    if (!this.loading_info.full) return this.loading_info.subnodes_count === 0;
    return this.findChildren().length === 0;
  }

  // returns new object reference with the link to itself
  getObjectReference(obj) {
    const objectCenter = obj.getRect().getCenter();

    return new ObjectReference({
      id: null,
      page_id: this.hierarchy.imageViewer.state.pageId,
      x_rel: objectCenter.x / this.hierarchy.imageViewer.imageWidth,
      y_rel: objectCenter.y / this.hierarchy.imageViewer.imageHeight,
      node: this,
    })
  }

  findAttributeByKey(attributeKey) {
    return this.attributes.find(attr => attr.key === attributeKey) ?? null;
  }

  hasAttribute(attributeKey) {
    const attribute = this.findAttributeByKey(attributeKey);
    return attribute !== null;
  }

  // returns null if attribute doesn't exist
  getAttributeValue(attributeKey) {
    const attribute = this.findAttributeByKey(attributeKey);
    if (!attribute) return null;
    return attribute.value;
  }

  addAttribute(newAttribute, callback=null) {
    const clonedNode = this.clone();
    clonedNode.attributes = [...clonedNode.attributes, newAttribute];
    return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
      return this.hierarchy.hierarchyView.loadHierarchy([], callback);
    });
  }

  updateText(value, callback=null) {
    if (this.textIsReadonly()) return Promise.reject();

    const clonedNode = this.clone();

    clonedNode.text = value;

    return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
      return this.hierarchy.hierarchyView.loadHierarchy([], callback);
    });
  }

  updateLabel(value, callback=null) {
    if (this.labelIsReadonly()) return Promise.reject();

    const clonedNode = this.clone();

    clonedNode.label = value;

    return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
      return this.hierarchy.hierarchyView.loadHierarchy([], callback);
    });
  }

  updateAttribute(updatedAttribute, callback=null) {
    const clonedNode = this.clone();
    clonedNode.attributes = clonedNode.attributes.map(attr => {
      if (attr.key === updatedAttribute.key) return updatedAttribute;
      return attr;
    });
    return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
      return this.hierarchy.hierarchyView.loadHierarchy([], callback);
    });
  }

  removeAttribute(attributeToRemove, callback=null) {
    const clonedNode = this.clone();
    clonedNode.attributes = clonedNode.attributes.filter(attr => attr.key !== attributeToRemove.key);
    return HierarchiesService.updateHierarchyNode(this.hierarchy, clonedNode).then(() => {
      return this.hierarchy.hierarchyView.loadHierarchy([], callback);
    });
  }

  clone() {
    const clonedNode = new HierarchyNode({
      id: this.id,
      text: this.text,
      label: this.label,
      parent_node_id: this.parent_node_id,
      references: [],
      attributes: this.attributes,
      hierarchy: this.hierarchy,
    })
    clonedNode.references = this.references.map(ref => {
      const clonedRef = ref.clone();
      clonedRef.node = clonedNode;
      return clonedRef;
    })
    return clonedNode;
  }

  getDict() {
    return {
      id: this.id,
      text: this.text,
      label: this.label,
      parent_node_id: this.parent_node_id,
      hierarchy_id: this.hierarchy.id,
      references: this.references.map(ref => ref.getDict()),
      attributes: this.attributes,
    };
  }

  hasOnPageReference() {
    return this.references.some(ref => ref.isOnPageReference());
  }

  // returns list the nodes on the path, Root is the last element of the list
  // node itself is not included
  getPathToRoot() {
    const result = [];
    let curNode = this;
    while (true) {
      const curParent = curNode.getParent();
      if (curParent) {
        result.push(curParent);
        curNode = curParent;
      } else break;
    }
    return result;
  }

  findChildren() {
    return this.hierarchy.nodes.filter(node => node.parent_node_id === this.id);
  }

  // returns list of all nodes from subtree, including itself
  getSubtreeNodes() {
    const children = this.findChildren();
    const subtreeNodes = [this, ...children.flatMap(child => child.getSubtreeNodes())]
    return subtreeNodes;
  }

  matchesObject(obj) {
    return this.references.some(ref => ref.matchesObject(obj));
  }

  // returns null if the object was not found
  // returns first match in case of multiple on page references
  findReferencedObject() {
    const objectsCandidates = this.hierarchy.imageViewer.state.allObjects.filter(obj => obj.text === this.text && obj.label === this.label);
    const matchedObject = objectsCandidates.find(obj => this.matchesObject(obj)) ?? null;
    return matchedObject;
  }

  getObjectAttributes() {
    const obj = this.findReferencedObject();
    if (obj) return Promise.resolve(obj.getAttributes());
    else if (!obj && this.references.length > 0) {
      return HierarchiesService.getHierarchyNodeReferenceTagReference(this.hierarchy, this, this.references[0]).then(res => {
        if (!res.data.tag_number) return null;
        return AllResultsService.getAnnotationObject(res.data).then(
            res => {
              if (res.data) return (new ImageViewerObject(res.data)).getAttributes();
              return null;
            }
        )
      })
    }

    return Promise.resolve(null);
  }

  // returns an object with matching text and label
  // returns null if such an object does not exist
  findPotentialObject() {
    return this.hierarchy.imageViewer.state.allObjects.find(obj => obj.text === this.text && obj.label === this.label) ?? null;
  }

  isValid() {
    return this.label.trim().length > 0 && this.text.trim().length > 0;
  }

  textIsReadonly() {
    return this.isRoot() || !this.isVirtual();
  }

  labelIsReadonly() {
    return this.isRoot() || !this.isVirtual();
  }
}
