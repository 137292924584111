import React, {useEffect, useState} from "react";
import {Button, Dropdown, Empty, Menu, Row, Spin, Tooltip} from "antd";
import axios from "axios";
import {API_URL} from "../../constants";
import {authHeader} from "../../Utilities";
import {HistoryOutlined} from "@ant-design/icons";

export function AnotherDrawingVersionSwitcher({resultId, isFinalResult, onVersionOptionClick, button}) {
    const [drawingVersionsData, setDrawingVersionsData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    const defaultButton = (<Button>Another versions</Button>);

    const optionName = (drawingVersion) => {
        let dateStr = '';

        if (drawingVersion.created_at) {
            const dateObj = new Date(drawingVersion.created_at);
            const dateParts = [
                dateObj.getFullYear(),
                ("0" + (dateObj.getMonth() + 1)).slice(-2),
                ("0" + dateObj.getDate()).slice(-2)
            ];

            dateStr = ` (${dateParts.join('/')})`;
        }

        let name = `${drawingVersion.name}`.substring(0, 25);

        if (drawingVersion.is_final) {
            if (drawingVersion.name) {
                name = `Finalized version: ${name}`;
            } else {
                name = `Finalized version ${name}`;
            }
        }

        const isAlreadyOpened = drawingVersion.result_id === resultId;
        const dateTag = <span style={{opacity: 0.5}}>{dateStr}</span>;
        const nameTag = <span>{name}</span>;

        let optionTag;
        if (isAlreadyOpened) {
            optionTag = <b>{nameTag}{dateTag}</b>;
        } else {
            optionTag = <span>{nameTag}{dateTag}</span>;
        }

        const tooltipProps = drawingVersion?.name?.length > 25 ? {} : {visible: false};
        return (
            <Tooltip
                title={drawingVersion.name}
                placement={"left"}
                {...tooltipProps}
            >
                {optionTag}
            </Tooltip>
        );
    }

    const menuItems = (
        drawingVersionsData
            .sort((a, b) => {
                const byIsFinalSort = b.is_final - a.is_final;

                if (byIsFinalSort === 0) {
                    return new Date(b.created_at) - new Date(a.created_at)
                } else {
                    return byIsFinalSort;
                }
            })
            .map((drawingVersion, idx) => (
                <Menu.Item disabled={dataLoading}
                           id={`drawing-version-option_${idx}`}
                           onClick={() => onVersionOptionClick(drawingVersion, drawingVersion.result_id === resultId)}>
                    {optionName(drawingVersion)}
                </Menu.Item>
            ))
    )

    const overlayMenu = (
        <Menu>
            {dataLoading ? <Spin spinning={dataLoading}>{menuItems}</Spin> : menuItems}
        </Menu>
    );

    const overlay = (
        drawingVersionsData.length > 0 ? overlayMenu : <Empty description={false}/>
    );

    return (
        <Dropdown
            trigger={"click"}
            overlay={overlay}
            size="small"
            onVisibleChange={visible => {
                if (visible) {
                    setDataLoading(true);

                    axios.post(API_URL + `/all_results/drawing_versions`,
                        {
                            result_reference: {
                                result_id: resultId,
                                is_final: isFinalResult
                            }
                        },
                        {
                            headers: authHeader()
                        }
                    ).then((res) => {
                        setDataLoading(false);
                        setDrawingVersionsData(res.data);
                    });
                }
            }}
        >
            {button ?? defaultButton}
        </Dropdown>
    )
}