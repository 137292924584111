import React from "react";
import {Tooltip} from "antd";

export function TrimmedText({text, maxLength=20, children, tooltipProps={}}) {
    const rawTextRender = text => text;
    const textRender = children ?? rawTextRender;

    const tooltipRender = sourceText => (
        <Tooltip {...tooltipProps} title={sourceText}>
            {textRender(`${sourceText.slice(0, maxLength)}...`)}
        </Tooltip>
    );

    const showTooltip = text.length > maxLength;
    const rawRender = sourceText => textRender(sourceText);

    const render = showTooltip ? tooltipRender : rawRender;

    return (
        render(text)
    );
}
