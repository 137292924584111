import React from "react";
import {
  Tag,
  Button,
  Form,
  Input,
  Row,
  Typography,
  Card,
  Menu,
  Dropdown,
  message,
  Select,
  Col,
  Space,
  Checkbox
} from 'antd'
import {Link} from "react-router-dom";
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import {API_URL, roleColors} from "../../constants";
import axios from "axios";
import {authHeader} from "../../Utilities";
import {PermissionsList} from "../Misc/PermissionsList";
import OrganizationsService from "../../services/OrganizationsService";
import UsersService from "../../services/UsersService";

const { Title } = Typography;
const { Option } = Select;


export class NewUser extends React.Component {
  state = {
    userRoles: ['user'],
    permissionsList: [],
    userData: null,
    organizationsList: [],
    currentUser: null,
    selectedOrganizationId: null,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  loadCurrentUser = () => {
    axios.get(API_URL + `/get_current_user`, {headers: authHeader()}).then(res => {
      this.setState({currentUser: res.data}, this.getOrganizationsList);
    }).catch(err => {
    })
  }

  getOrganizationsList = () => {
    if (this.state.currentUser.roles.includes('admin')) {
      OrganizationsService.fetchOrganizations().then(orgsList => {
        this.setState({organizationsList: orgsList.data});
      }).catch(() => message.error("Failed to load organizations list"));
    } else {
      this.setState({organizationsList: [this.state.currentUser.organization]})
      this.formRef.current.setFieldsValue({organization: this.state.currentUser.organization.id})
    }
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  onSubmit = (values) => {
    UsersService.addUser({
      ...values,
      'roles': this.state.userRoles,
      'permissions': this.state.permissionsList,
    }).then(result => {
      message.success('User created');
      this.props.history.push(`${this.props.root}users`);
    }).catch(err => {
      if (err.response.status === 409)
        message.error('User already exists');
      else
        message.error('Failed to add user');
    });
  }

  getAvailablePermissionsTypes = () => {
    const isAdmin = this.state.currentUser?.roles?.includes('admin');
    if (!isAdmin) return ['view_results', 'finalize_results', 'hierarchies', 'comments'];
    if (this.state.userRoles.includes('admin') || this.state.userRoles.includes('analyst'))
      return ['view_results', 'finalize_results', 'data_extraction', 'hierarchies', 'comments'];
    return ['view_results', 'finalize_results', 'hierarchies', 'comments'];
  }

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const rolesToAdd = ['user', 'analyst', 'admin', 'manager'].filter(r => !this.state.userRoles.includes(r));

    const menu = (
        <Menu>
          {
            rolesToAdd.map(role => (
              <Menu.Item onClick={() => this.setState({userRoles: [...this.state.userRoles, role]})}>
                {role}
              </Menu.Item>
            ))
          }
        </Menu>
    )

    const organizationPermissions = this.state.organizationsList.filter(
        org => org.id === this.state.selectedOrganizationId
    )[0]?.permissions || [];

    const isAdmin = this.state.currentUser?.roles?.includes('admin');
    const rolesEditable = isAdmin;

    const availablePermissionsTypes = this.getAvailablePermissionsTypes();
    return (
      <>
        <Row style={{justifyContent: 'center'}}>
          <Card
              title="New user"
              style={{width: '100%'}}>
          <Form {...layout} ref={this.formRef} name="new-user-form" onFinish={this.onSubmit}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input user name' }, { min: 3 }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input user last name' }, { min: 3 }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item name={'email'} label="Email" rules={[{ type: 'email', required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item name="organization" label="Organization" rules={[{ required: true }]} >
                  <Select
                      showSearch
                      disabled={!isAdmin}
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(newValue) => this.setState({selectedOrganizationId: newValue})}
                  >
                    {
                      this.state.organizationsList.map(org => (<Option value={org.id}>{org.name}</Option>))
                    }
                  </Select>

                </Form.Item>

                <Form.Item
                    label="Roles"
                    name="roles"
                >
                {
                  this.state.userRoles.map((role) => {
                    return (
                        <Tag key={`tag-${role}`} color={roleColors[role]}
                             closable={rolesEditable}
                             onClose={(e) => {
                               e.preventDefault();
                               this.setState({
                                 userRoles: this.state.userRoles.filter(r => r !== role)
                               })
                             }}
                        >
                          {role}
                        </Tag>
                    )
                  })
                }
                {
                  rolesEditable && rolesToAdd.length > 0 && (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Tag style={{borderStyle: 'dashed', background: '#fff'}}>
                        <PlusOutlined /> New role
                      </Tag>
                    </Dropdown>
                  )
                }

                </Form.Item>
                <Form.Item label="Send registration email" name="send_registration_email" initialValue={true} valuePropName="checked">
                  <Checkbox disabled={!isAdmin} />
                </Form.Item>
              </Col>

              <Col span={12}>

                <PermissionsList
                    permissionsList={this.state.permissionsList}
                    availablePermissionsTypes={availablePermissionsTypes}
                    isEditable={true}
                    onChange={perms => {this.setState({permissionsList: perms})}}
                    style={{marginBottom: '16px'}}
                />

                <PermissionsList
                    title="Organization permissions"
                    permissionsList={organizationPermissions}
                    isEditable={false}
                    style={{marginBottom: '16px'}}

                />
              </Col>

              <Space style={{marginLeft: 'auto'}}>
                <Button><Link to={`${this.props.root}users`}>Cancel</Link></Button>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                  Create
                </Button>
              </Space>
            </Row>
          </Form>
        </Card>
        </Row>



      </>
    )
  }
}