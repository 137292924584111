import React, {useEffect, useState} from "react";
import {AutoComplete, message, Tooltip} from "antd";

export function EndlessScrollRemoteAutocomplete({id, fetchOptions, onChange, onSelect, baseMaxResults=20,
                                       maxResultsIncreaseFactor=20}) {
    const [options, setOptions] = useState([]);
    const [loadTimeout, setLoadTimeout] = useState(null);
    const [maxResults, setMaxResults] = useState(baseMaxResults);
    const [value, setValue] = useState("");

    useEffect(() => {
        loadOptionsImmediately(value, maxResults);
    }, []);

    function onValueChanged(value) {
        setValue(value);
        setMaxResults(baseMaxResults);
    }

    function setOptionsFromData(data) {
        const printDataValue = (value) => {
            if (value.length < 20) {
                return <span>{value}</span>
            }

            return (
                <Tooltip title={value} placement={"left"}>
                    <span>{value.slice(0, 20)}...</span>
                </Tooltip>
            )
        }

        setOptions(data.map(item => (
            {
                value: item.value,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        {printDataValue(item.value)}
                        <span style={{color:"grey"}}>({item.count})</span>
                    </div>
                ),
                count: item.count
            })
        ));
    }

    function loadOptionsImmediately(value, maxResults) {
        fetchOptions(value, maxResults).then(response => {
            setOptionsFromData(response.data);
        }).catch(e => {
            message.error("Failed to load values");
            setOptions([]);
        })
    }

    function loadOptionsWithTimer(value, maxResults) {
        if (loadTimeout != null) clearTimeout(loadTimeout);

        const timeout = setTimeout(
            () => {
                loadOptionsImmediately(value, maxResults)
            }, 500
        )

        setLoadTimeout(timeout);
    }

    return (
        <AutoComplete
            id={id}
            placeholder="(empty)"
            onPopupScroll={e => {
                if (options.length < maxResults) return;

                e.persist();
                const target = e.target;
                if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                    loadOptionsImmediately(value, maxResults + maxResultsIncreaseFactor);
                    setMaxResults(maxResults + maxResultsIncreaseFactor);
                }
            }}
            value={value}
            size={"small"}
            options={options}
            onSearch={val => {
                loadOptionsWithTimer(val, baseMaxResults)
            }}
            onChange={val => {
                onValueChanged(val);
                onChange(val);
            }}
            onSelect={(val, opt) => {
                onValueChanged(val);
                setOptionsFromData([
                    {
                        "value": val,
                        "count": opt.count
                    }
                ])
                onSelect(val);
            }}
        >
        </AutoComplete>
    );
}