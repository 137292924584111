import {EditedFilter} from "./EditedFilter";

export class SimpleEditedFilter extends EditedFilter {
    constructor(attr, value, schemas) {
        super();

        this._attr = attr;
        this._value = value;
        this._schemas = schemas;
    }

    canBeAdded() {
        return this._attr != null && this._value != null;
    }

    toConcreteFilter() {
        const schema = this._schemas.find((schema) => schema.attribute === this._attr);
        const newFilter = schema.constructor(this._value);

        return newFilter;
    }
}