import React from 'react';
import { FilesNavigation } from '../components/FilesNavigation';
import { API_URL } from '../constants'
import { Modal, Space } from 'antd';
import axios from 'axios'
import { authHeader } from "../Utilities";


export class S3FilesNavigationContainer extends React.Component {
  defaultPageSize = 20;
  defaultPage = 1;

  constructor(props) {
    super(props);

    this.state = {
      dirs: [],
      files: [],
      total: 0,
      sort: {},
      page: this.defaultPage,
      pageSize: this.defaultPageSize,
      folder: '',
    }
  }


  loadFolderContent(bucket, folder, page, size, sortField, sortOrder) {
    this.setState({
      isLoading: true,
    });

    const params = {
      bucket,
      folder,
      page,
      size,
      sortField,
      sortOrder,
      bucket_alias: this.props.bucketAlias,
      aws_profile: this.props.awsProfile,
      aws_access_key_id: this.props.awsAccessKeyId,
      aws_secret_access_key: this.props.awsSecretAccessKey,
    };

    const url = `${API_URL}/s3/folder/content`;
    return axios.get(url, { params, headers: authHeader() })
      .then(res => res.data)
      .then(result => {
        const { dirs, files, total } = result;
        this.setState({
          dirs,
          files,
          total,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.error && this.state.error != prevState.error) {
      Modal.error({
        title: 'Error loading files',
      })
    }
  }

  constructFullPath(item) {
    const fullPath = item.folder === '' ? item.name : item.folder + '/' + item.name;
    return fullPath;
  }

  refreshData() {
    const { page, pageSize, sort, folder } = this.state;
    const { field: sortField, order: sortOrder } = sort;

    if (folder != null)
      this.loadFolderContent(this.props.bucket, folder, page, pageSize, sortField, sortOrder);
  }

  onDownloadFolder(item) {
    const folder = this.constructFullPath(item);
    if (this.props.onDownloadFolder)
      this.props.onDownloadFolder(folder);
  }

  onDirDoubleClick(item) {
    this.changeDir(this.constructFullPath(item));
  }

  onBreadcrumbClick(folder) {
    this.changeDir(folder);
  }

  tableChange(pagination, filters, sorter, extra) {
    this.setState({
      page: pagination.current,
      pageSize: pagination.pageSize,
      sort: sorter,
    }, () => this.refreshData());
  }

  changeDir(folder) {
    this.setState({
      page: 1,
      folder,
    }, () => this.refreshData());
  }

  getTableProps(props) {
    return {
      tableProps: {
        pagination: {
          pageSize: props.pageSize,
          current: props.page,
          total: props.total,
        },
      },
      tableColumnsProps: {
        sorter: props.sort,
      },
    }
  }


  getActions(item) {
    if (item.type === 'dir') {
      return (
        <a onClick={e => this.onDownloadFolder(item)}>Download</a>
      )
    }
    else {
      return (<></>)
    }
  }


  render() {
    const { dirs, files, isLoading, folder } = this.state;
    const { tableProps, tableColumnsProps } = this.getTableProps(this.state);

    const data = dirs.concat(files).map(item => {
      return {
        ...item,
        key: this.constructFullPath(item),
      }
    });

    return (
      <>
        <FilesNavigation
          data={data}
          folder={folder}
          isLoading={isLoading}
          tableProps={tableProps}
          tableColumnsProps={tableColumnsProps}
          hideSearchField={true}
          hideDowloadFolderButton={this.props.hideDowloadFolderButton}

          actionsCreator={this.getActions.bind(this)}
          onBreadcrumbClick={this.onBreadcrumbClick.bind(this)}
          tableChange={this.tableChange.bind(this)}
          onDirDoubleClick={this.onDirDoubleClick.bind(this)}
          onDownloadFolder={this.onDownloadFolder.bind(this)}
          onFileDoubleClick={this.props.onFileDoubleClick}
        />
      </>
    );
  }
}