import React, {useState} from "react";
import {Button, Col, Row, Divider, Form, Popover, Select, Space, Tag, Tooltip} from "antd";
import {Option} from "antd/es/mentions";
import WildcardService from "../../services/WildcardService";
import {postprocessed} from "../../Utilities";

export const emptyAlias = "(empty)";

export function DataSourceFilterApplier({itemFilters, dataSource, dataSourceRenderer}) {
    dataSource = dataSource.filter((item) => isObjectMatchFilters(item, itemFilters));

    return (dataSourceRenderer(dataSource));
}

export function DataSourceFiltersEditor({itemFilters, onItemFiltersChanged, filterSchemas, contentRenderer,
                                            fieldEditorRenderer}) {
    function addNewFilter(newFilter) {
        itemFilters = newFilter.addTo(itemFilters);

        onItemFiltersChanged(newFilter.addTo(itemFilters));
    }

    const tagsList = (
        <FiltersList itemFilters={itemFilters} onItemFiltersChanged={onItemFiltersChanged}/>
    );

    const defaultFieldEditor = (
        <>
            <DataSourceFilterEditor
                filterSchemas={filterSchemas}
                onNewFilter={addNewFilter}
            />
        </>
    )

    function defaultRenderer(tagsList, editor) {
        return (
            <Row justify={"space-between"}>
                <Col span={15}>
                    {tagsList}
                </Col>
                <Col span={9}>
                    {editor}
                </Col>
            </Row>
        )
    }

    const currentRenderer = contentRenderer ?? defaultRenderer;
    const currentEditor = fieldEditorRenderer ? fieldEditorRenderer(filterSchemas, addNewFilter) : defaultFieldEditor;

    return (
        currentRenderer(tagsList, currentEditor)
    )
}

function DataSourceFilterEditor({filterSchemas, onNewFilter}) {
    const [visible, setVisible] = useState(false);

    const [allowedValues, setAllowedValues] = useState([]);
    const allowedAttributes = [...new Set(filterSchemas.map((schema) => schema.attribute))];

    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const searchableSelectProps = {
        size: "small",
        showSearch: true,
        filterOption: filterOption,
    }

    function calculateAllowedValuesBySelectedAttribute(selectedAttribute) {
        let newAllowedValues = [];

        if (selectedAttribute != null) {
            const schema = filterSchemas.find((schema) => schema.attribute === selectedAttribute);

            newAllowedValues = [...new Set(schema.allowedValues)];
        }

        setAllowedValues(newAllowedValues);
    }

    function filterOption(input, option) {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    const editorContent = (
        <div style={{width: "288px"}}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}}>
                <Form.Item label="Attribute">
                    <Select
                        id="attribute-select"
                        {...searchableSelectProps}
                        value={selectedAttribute}
                        onSelect={(attr) => {
                            setSelectedAttribute(attr);
                            calculateAllowedValuesBySelectedAttribute(attr);
                            setSelectedValue(null);
                        }}
                    >
                        {
                            allowedAttributes.map((attr, idx) => {
                                const option = attr === "" ? emptyAlias : attr;
                                return (<Option id={`attribute-select-option_${attr}`} value={attr}>{option}</Option>);
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Value">
                    <Select
                        id="value-select"
                        {...searchableSelectProps}
                        value={selectedValue}
                        onSelect={(value) => setSelectedValue(value)}
                    >
                        {
                            allowedValues.map((val, idx) => {
                                const option = val === "" ? emptyAlias : val;
                                return (<Option id={`value-select-option_${val}`} value={val}>{option}</Option>);
                            })
                        }
                    </Select>
                </Form.Item>
                <Row>
                    <Space style={{marginLeft: 'auto'}}>
                        <Button
                            id="cancel-filter-button"
                            size="small"
                            onClick={() => setVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="apply-filter-button"
                            type="primary" size="small"
                            onClick={() => {
                                if (selectedAttribute == null || selectedValue == null) {
                                    return;
                                }

                                const schema = filterSchemas.find((schema) => schema.attribute === selectedAttribute);
                                const newFilter = schema.constructor(selectedValue);

                                onNewFilter(newFilter);
                                setVisible(false);
                            }}
                        >
                            Add
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    );

    return (
        <Popover
            visible={visible}
            trigger="click"
            title="Add filter"
            placement="bottom"
            content={editorContent}
            onVisibleChange={visible => {
                setSelectedValue(null);
                setSelectedAttribute(null);
                setAllowedValues([]);
                setVisible(visible);
            }}
        >
            <Button
                id="add-filter-button"
                icon={<img src={process.env.PUBLIC_URL + '/images/filter_list-24px.svg'}/>}
                size="small"
            >
                Add filter
            </Button>
        </Popover>
    )
}

export function isObjectMatchFilters(obj, filters) {
    for (let i = 0; i < filters.length; i++) {
        if (!filters[i].match(obj)) {
            return false;
        }
    }

    return true;
}

export class DataItemFilter {
    constructor(value, dataExtractor, filterAlias="", onRemove=null) {
        this.dataExtractor = dataExtractor;
        this.value = value;
        this.filterAlias = filterAlias;
        this.onRemove = onRemove;
    }

    match(item) {
        const itemValue = this.dataExtractor(item);
        return this._matchLogic(itemValue);
    }

    addTo(filters) {
        const findIndex = filters.findIndex(el => el.filterAlias === this.filterAlias);

        if (findIndex > -1) {
            filters[findIndex] = this;
        } else {
            filters.push(this);
        }

        return filters;
    }

    getName() {
        return this.filterAlias;
    }

    getDisplayValue() {
        return this.value;
    }

    _matchLogic(itemValue) {
        return false;
    }
}

export class ExactMatchItemFilter extends DataItemFilter {
    _matchLogic(itemValue) {
        return itemValue === this.value;
    }
}

export class SearchItemFilter extends DataItemFilter {
    _matchLogic(itemValue) {
        if (!itemValue) {
            itemValue = '';
        }

        return itemValue.toString().toLowerCase().includes(this.value.toString().toLowerCase());
    }

    addTo(filters) {
        if (!this.value) {
            filters = filters.filter(el => el.filterAlias !== this.filterAlias);
            return filters;
        }

        return super.addTo(filters);
    }
}

export class PostprocessedSearchItemFilter extends SearchItemFilter {
    _matchLogic(itemValue) {
        if (!itemValue) {
            itemValue = '';
        }

        return postprocessed(itemValue.toString()).includes(postprocessed(this.value.toString()));
    }
}

export class WildcardsSearchItemFilter extends SearchItemFilter {
    constructor(value, dataExtractor, filterAlias, onRemove) {
        super(value, dataExtractor, filterAlias, onRemove);

        this.wildcardPattern = WildcardService.getPattern(value);
    }

    getName() {
        return `${this.filterAlias} (wildcard)`;
    }

    _matchLogic(itemValue) {
        if (!itemValue) {
            itemValue = '';
        }

        return this.wildcardPattern.match(itemValue);
    }
}

export function FiltersList({itemFilters, onItemFiltersChanged}) {
    function clearAllFilters() {
        itemFilters = [];

        onItemFiltersChanged(itemFilters);
    }

    function removeFilter(target) {
        itemFilters = itemFilters.filter((filter) => filter !== target)

        onItemFiltersChanged(itemFilters);
    }

    const tags = itemFilters.map((filter, idx) => {
        let isTrimmed = false;

        function detectTrim() {
            isTrimmed = true;
        }

        function trim(text, trimCallback) {
            const maxLen = 15;
            if (text.length > maxLen) {
                text = `${text.slice(0, maxLen)}...`

                if (trimCallback) {
                    trimCallback();
                }
            }

            return text;
        }

        const attrText = trim(filter.getName() ? filter.getName() : emptyAlias, detectTrim);
        const valText = trim(filter.getDisplayValue() ? filter.getDisplayValue() : emptyAlias, detectTrim);
        const color = filter.getColor ? filter.getColor() : "blue";

        const tagElement = (
            <Tag
                id={`filter-tag_${idx}`}
                key={filter.filterAlias+filter.value}
                color={color}
                onClose={() => {
                    removeFilter(filter);
                }}
                closable
            >
                <b>
                    <span style={{color: "black"}}>
                        {attrText}:&nbsp;
                    </span>
                </b>
                <span style={{color: "black"}}>
                    {valText}
                </span>
            </Tag>
        );

        return (
            isTrimmed ? (
                <Tooltip title={`${filter.getName()}: ${filter.getDisplayValue()}`} key={filter.filterAlias+filter.value}>
                    {tagElement}
                </Tooltip>
            ) : (tagElement)
        )
    });

    const clearFiltersButton = (
        <>
            <Divider type={"vertical"}/>
            <Button id="clear-all-filters-button" size="small" onClick={clearAllFilters}>Clear filters</Button>
        </>
    );

    const tagsList = (
        <>
            {tags}
            {itemFilters.length > 0 && clearFiltersButton}
        </>
    )

    return tagsList;
}
