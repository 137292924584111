export default class Hierarchy {
  constructor({id, name, nodes, imageViewer, hierarchyView}) {
    this.id = id;
    this.name = name;
    this.nodes = nodes;
    this.imageViewer = imageViewer;
    this.hierarchyView = hierarchyView;
  }

  getNodeById(nodeId) {
    return this.nodes.find(node => node.id === nodeId) ?? null;
  }

  getRootNode() {
    return this.nodes.find(node => node.parent_node_id === null);
  }

  // returns null if the node doesn't exist
  findNode(text, label) {
    return this.nodes.find(node => node.text === text && node.label === label) ?? null;
  }

  // returns null if there is no such node
  findNodeByObject(obj) {
    const node = this.findNode(obj.text, obj.label);
    if (!node) return null;
    return node;
  }

  isValidSubnodesAttributeValue(value) {
    if (value.trim().length === 0) return false;
    return value.split(',').every(s => {
      const spl = s.split('|');
      if (spl.length !== 2) return false;
      return spl[0].trim().length > 0 && spl[1].trim().length > 0;
    });
  }

  hasSubnodesAttribute(obj) {
    return this.getSubnodesAttributesKeys(obj).length > 0;
  }

  getSubnodesAttributesKeys(obj) {
    return obj.metadata.attributes.filter(attr => this.isValidSubnodesAttributeValue(attr.value)).map(attr => attr.key);
  }
}
