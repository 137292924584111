import React from 'react'
import { Table, Input, Space, Button } from 'antd'
import { FolderOutlined } from '@ant-design/icons';
import { PathBreadcrumbs } from './PathBreadcrumbs'
import filesize from 'filesize';
import { Menu } from 'antd';

const { SubMenu } = Menu;
const { Search } = Input;

export class FilesNavigation extends React.Component {
  constructor(props) {
    super(props);
  }

  onItemDoubleClick(item) {
    if (item.type === 'dir') {
      if (this.props.onDirDoubleClick) {
        this.props.onDirDoubleClick(item);
      }
    }
    else {
      if (this.props.onFileDoubleClick) {
        this.props.onFileDoubleClick(item);
      }
    }
  }

  getIcon(item) {
    const style = { fontSize: '1.5em', marginRight: '0.5em' };

    if (item.type === 'file') {
      const extension = item.name.split('.').pop().toLowerCase();
      return <span
        className={`fiv-viv fiv-icon-${extension} fiv-icon-blank`}
        style={style} />
    }
    else {
      return <FolderOutlined style={style} />
    }
  }

  getActions(item) {
    if (item.type === 'file') {
      return (
        <Space>
          <a onClick={e => this.onDownloadFile(item)}>Download</a>
          <Menu mode="vertical" selectable={false}>
            <SubMenu key="sub1" title="Actions">
              <Menu.Item key="1" onClick={e => this.onDeleteFile(item)} >Delete</Menu.Item>
            </SubMenu>
          </Menu>
        </Space>
      )
    }
    else {
      return (
        <Space>
          <a onClick={e => this.onDownloadFolder(item)}>Download</a>
        </Space>
      )
    }
  }

  onDownloadFile(item) {
    if (this.props.onDownloadFile) {
      this.props.onDownloadFile(item);
    }
  }

  onDownloadFolder(item) {
    if (this.props.onDownloadFolder) {
      this.props.onDownloadFolder(item);
    }
  }

  onDeleteFile(item) {
    if (this.props.onDeleteFile) {
      this.props.onDeleteFile(item);
    }
  }



  getColumns() {
    const { sorter } = this.props.tableColumnsProps;
    const { search } = this.props;
    const { field: sortField, order: sortOrder } = sorter || {};

    const columns = [
      {
        title: 'File name',
        dataIndex: 'name',
        sorter: true,
        render: (text, record, index) => (
          <>
            {this.getIcon(record)}
            {text}
          </>
        ),
      },
      {
        title: 'Modified date',
        dataIndex: 'uploadDate',
        sorter: true,
      },
      {
        title: 'Size',
        dataIndex: 'size',
        sorter: true,
        render: (text, record, index) => text != null ? filesize(text) : null,
      },
    ];

    if (search) {
      columns.push({
        title: 'Parent folder',
        dataIndex: 'folder',
        sorter: true,
      });
    }

    columns.push({
      title: 'Actions',
      render: (text, record, index) => {
        const actionsCreator = this.props.actionsCreator ?? this.getActions.bind(this);
        return actionsCreator(record);
      },
    });

    if (sortField != null) {
      const sortColumn = columns.find(c => sortField === c.dataIndex);
      if (sortColumn) {
        sortColumn.sortOrder = sortOrder;
      }
    }

    return columns;
  }

  render() {
    const { data, folder, isLoading, search, hideSearchField } = this.props;
    const folderName = folder.substring(folder.lastIndexOf("/") + 1);
    const parentFolder = folder.substring(0, folder.lastIndexOf("/"))

    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <PathBreadcrumbs {...this.props} path={folder} />
        <Space>
          {!hideSearchField &&
            <Search key={search ?? ""}
              placeholder="input search text"
              defaultValue={search}
              onSearch={this.props.onSearch}
              enterButton
              allowClear
              loading={isLoading}
              style={{ width: 300 }}
            />
          }

          {!this.props.hideDowloadFolderButton && <Button
              type="primary"
              disabled={folder == null}
              onClick={() => this.onDownloadFolder({ folder: parentFolder, name: folderName })}
          >
            Download current folder
          </Button>}
        </Space>

        <Table
          size="small"
          dataSource={data}
          columns={this.getColumns()}
          loading={isLoading}
          {...this.props.tableProps}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: event => this.onItemDoubleClick(record),
            };
          }}
          onChange={this.props.tableChange}
        />
      </Space>
    )
  }
}