import React from 'react';
import {Button, message, Row, Tag, Typography} from 'antd';
import {Link} from "react-router-dom"
import axios from "axios";
import {API_URL, roleColors} from "../../constants";
import {addTableTextFilters, authHeader} from "../../Utilities";
import {EditOutlined} from '@ant-design/icons';
import {AsyncDataSource, ManualPagedTable} from "../Misc/Table/ManualPagedTable";
import OrganizationsService from "../../services/OrganizationsService";

const { Title } = Typography;

// class NewWorkspaceModal extends React.Component {
//   state = {
//     name: '',
//   }
//
//   handleOK = () => {
//     axios.post(
//         API_URL + `/workspaces`,
//         { name: this.state.name },
//         {headers: authHeader()},
//     ).then(result => {
//       this.props.handleRefresh();
//       this.props.handleHide();
//     }).catch(err => {
//       message.error('Failed to create workspace');
//     });
//   }
//
//   handleCancel = () => {
//     this.props.handleHide();
//   }
//
//   render() {
//     return (
//         <>
//           <Modal
//               title="New workspace"
//               visible={this.props.visible}
//               onOk={this.handleOK}
//               onCancel={this.handleCancel}
//           >
//             <Form.Item
//                 label="Name"
//                 name="name"
//                 rules={[{ required: true, message: 'Please input the workspace name' }]}
//             >
//               <Input value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
//             </Form.Item>
//           </Modal>
//         </>
//     )
//   }
// }


class AsyncDataSourceUsers extends AsyncDataSource {
  constructor(email, first_name, last_name, roles, organizations) {
    super();
    this._email = email ?? "";
    this._first_name = first_name ?? "";
    this._last_name = last_name ?? "";
    this._roles = roles ?? [];
    this._organizations = organizations ?? [];
  }

  async values(page, limit) {
    const params = new URLSearchParams();
    params.append("page", encodeURIComponent(page));
    params.append("limit", encodeURIComponent(limit));
    params.append("first_name", encodeURIComponent(this._first_name));
    params.append("last_name", encodeURIComponent(this._last_name));
    params.append("email", encodeURIComponent(this._email));
    this._organizations.forEach(org => params.append("organization", org));
    this._roles.forEach(role => params.append("role", role));
    return await axios.get(
        API_URL + `/users`,
        {
          params: params,
          headers: authHeader()
        }
    ).then(res => res.data).catch(err => {
      message.error('Failed to load users');
      return [];
    });
  }
}

export class UsersList extends React.Component {
  state = {
    hoveredRowIndex: null,
    dataSource: new AsyncDataSourceUsers(),
    prevFilters: null,
    organizationsFilters: []
  }

  constructor(props) {
    super(props);

    addTableTextFilters(this);
  }

  componentDidMount() {
    OrganizationsService.fetchOrganizations().then(({data}) => this.setState({
      organizationsFilters: data.map(org => ({
        text: org.name,
        value: org.id
      })).sort((a, b) => a.text < b.text)
    }));
  }

  onTableChange = (pagination, filters) => {
    const hasFilters = Object.keys(filters).filter(key => filters[key] != null).length > 0;
    const firstFiltersSet = this.state.prevFilters == null && hasFilters;
    const newFiltersSet = this.state.prevFilters != null && JSON.stringify(this.state.prevFilters) !== JSON.stringify(filters);
    if (firstFiltersSet || newFiltersSet) {
      this.setState({
        dataSource: new AsyncDataSourceUsers(
            filters.email, filters.first_name, filters.last_name, filters.roles, filters.organization
        ),
        prevFilters: filters
      });
    }
  }

  render() {
    const organizationsFilters = this.state.organizationsFilters;
    const columns = [
      {
        title: 'First Name',
        dataIndex: 'first_name',
        ...this.getColumnSearchProps('first_name'),
        onFilter: () => true
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        ...this.getColumnSearchProps('last_name'),
        onFilter: () => true
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ...this.getColumnSearchProps('email'),
        onFilter: () => true
      },
      {
        title: 'Organization',
        dataIndex: 'organization',
        filters: organizationsFilters,
        onFilter: () => true,
        render: match => {
          return match.name;
        }
      },
      {
        title: 'Roles',
        dataIndex: 'roles',
        filters: [
          {
            text: 'User',
            value: 'user',
          },
          {
            text: 'Manager',
            value: 'manager',
          },
          {
            text: 'Analyst',
            value: 'analyst',
          },
          {
            text: 'Admin',
            value: 'admin',
          },
        ],
        onFilter: () => true,
        render: (roles) => {
          return roles.map(role => (
              <Tag color={roleColors[role]}>{role}</Tag>
          ));
        }
      },
      {
        title: '',
        dataIndex: 'id',
        width: '48px',
        render: (id, record, index) => {
          return index === this.state.hoveredRowIndex && (
              <Link to={`${this.props.root}users/${id}/edit`}>
                <EditOutlined />
              </Link>
          );
        }
      }
    ];

    return (
        <>
          <Row style={{justifyContent: 'space-between', padding: '1em'}}>
            <Title level={4}>Users</Title>
            <Link to={`${this.props.root}users/new`}>
              <Button type="primary" onClick={this.showModal}>New user</Button>
            </Link>
          </Row>
          <ManualPagedTable
              onChange={this.onTableChange}
              asyncDataSource={this.state.dataSource}
              columns={columns}
              onRow={(record, rowIndex) => {
                return {
                  onMouseEnter: event => this.setState({hoveredRowIndex: rowIndex}),
                  onMouseLeave: event => this.setState({hoveredRowIndex: null}),
                }
              }}
          />
          {/*<NewWorkspaceModal*/}
          {/*    visible={this.state.newWorkspaceModalVisible}*/}
          {/*    handleHide={this.hideModal}*/}
          {/*    handleRefresh={this.loadWorkspaces}*/}
          {/*/>*/}
        </>
    );
  }
}
